import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import * as L from 'leaflet';
import {TileLayer, tileLayer} from 'leaflet';
import {TrackingObject, TripService} from '../../../services/trip.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {DataService} from '../../../services/data.service';
import {takeUntil} from 'rxjs/operators';
import {from, Subject} from 'rxjs';
import {AlertPopupComponent} from '../../_shared/alert-popup/alert-popup.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-drivers-positions',
  templateUrl: './drivers-position.component.html',
  styleUrls: ['./drivers-position.component.scss']
})
export class DriversPositionComponent implements OnInit, OnDestroy, AfterViewInit {
  private map: L.DrawMap;
  private tiles: TileLayer[];
  private leafletOptions: { layers: TileLayer[]; zoom: number };
  form: UntypedFormGroup;
  project_id: number;
  drivers: any[] = [];
  driver: any = null;
  groups: any[] = [];
  group: any = null;
  project_tags: any[] = [];
  tag: any = null;
  destroy$ = new Subject();

  constructor(private tripService: TripService, private fb: UntypedFormBuilder, private dataService: DataService,
              private modal: NgbModal) {
  }

  ngOnInit(): void {
    this.project_id = this.dataService.project.project_id;
    this.dataService.getProjectTags(this.project_id).subscribe(project_tags => {
      this.project_tags = project_tags;
    });
    this.dataService.getProjectGroups().subscribe(project_groups => {
      this.groups = project_groups;
    })
    this.form = this.fb.group({
      user_id: [''],
    });
    this.dataService.getProjectRolesUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        this.drivers = result.filter(u => u.system && u.system.system_type === this.dataService.project.systems[0].system_type);
        if (this.dataService.project.systems[0].system_type === 'teltonika') {
          const favourite_driver = 'BartDeVos';
          const index = this.drivers.findIndex(obj => obj.system.teltonika.short_name === favourite_driver);
          const removed = this.drivers.splice(index, 1)[0];
          this.drivers.unshift(removed);
          this.loadTracking();
        }
      }, error => {
        const ref = this.modal.open(AlertPopupComponent, { centered: true });
        ref.componentInstance.title = 'Error';
        ref.componentInstance.msg = error.error.message;
        return from(ref.result);
      });
  }

  loadTracking() {
    const projectId = this.dataService.project.project_id;
    let driverId = null;
    let communityId = null;
    let tagName = null;
    if (this.driver) {
      driverId = this.driver.user_id;
    }
    if (this.group) {
      communityId = this.group.id;
    }
    if (this.tag) {
      tagName = this.tag.tag_name;
    }
    this.tripService.liveTracking(projectId, driverId, communityId, tagName)
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(result => {
        this.removeMarkers();
        if (!Array.isArray(result)) {
          this.attachMarkers([result]);
        } else {
          this.attachMarkers(result);
        }
        this.boundMap();
      }, (error) => {
        const ref = this.modal.open(AlertPopupComponent, { centered: true });
        ref.componentInstance.title = 'Error';
        ref.componentInstance.msg = error.error.message;
        return from(ref.result);
      });
  }

  ngAfterViewInit(): void {
    this.tiles = [
      tileLayer('https://imobwww.uhasselt.be/osm_tiles/{z}/{x}/{y}.png', {
        maxZoom: 19,
        // tileSize: 512,
        attribution: '&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap contributors</a>',
      }),
    ];

    this.leafletOptions = {
      layers: [this.tiles[0]],
      zoom: 9,
    };
    this.map = L.map('map', this.leafletOptions).setView(
      new L.LatLng(
        51.143717, 4.616845),
      14,
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  selectUser(driver: any) {
    this.driver = driver;
    this.group = null;
    this.tag = null;
    this.loadTracking();
  }

  selectGroup(group: any) {
    this.driver = null;
    this.group = group;
    this.tag = null;
    this.loadTracking();
  }

  selectTag(tag: any) {
    this.driver = null;
    this.group = null;
    this.tag = tag;
    this.loadTracking();
  }

  private attachMarkers(result: TrackingObject[]) {
    // need to add markers to the map using the result
    result.forEach((item, index) => {
      const trace_points = [];
      trace_points.push([item.lat, item.lon]);
      // when hoovering over a marker, show the driver_id and the time of the current position
      const driver = this.drivers.find(d => d.user_id === item.user_id);
      let driverName = '';
      if (!driver) {
        driverName = '';
      } else {
        driverName = `${driver.first_name ? driver.first_name : ''} ${driver.last_name ? driver.last_name : ''}`;
      }
      const popupContent = `
    <div class="marker-popup-position">Driver: ${driverName}<br>Time: ${new Date(item.timestamp * 1000).toLocaleString()}</div>`;
      L.marker(trace_points[0], {
        icon: L.icon({
          iconUrl: 'assets/marker/trip_end.svg',
          iconSize: [45, 50],
          iconAnchor: [23, 50],
        }),
        draggable: false,
      }).bindPopup(
        popupContent,
        {}
      ).addTo(this.map);
    });
  }

  private boundMap() {
    const group = L.featureGroup([]);
    this.map.eachLayer((layer) => {
      if (layer instanceof L.Marker) {
        group.addLayer(layer);
      }
    });
    this.map.fitBounds(group.getBounds(), {
      animate: true,
      maxZoom: 16,
    });
  }

  private removeMarkers() {
    this.map.eachLayer((layer) => {
      if (layer instanceof L.Marker) {
        this.map.removeLayer(layer);
      }
    });
  }
}
